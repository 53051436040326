<template>
  <v-app>
    <MainPage />
  </v-app>
</template>

<script>

import MainPage from './components/MainPage.vue'

export default {
  name: 'App',

  components: {
    MainPage
  },

  data: () => ({
    //
  }),
};
</script>
