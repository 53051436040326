<template>
<div>
    <v-card flat tile>
        <v-card flat tile class="mx-auto" :width="mainWidth">
            <v-card-text class="px-2 px-sm-4">
                <div v-if="permitions" class="caption font-italic">Станом на {{formatDate(dogsData.end_date)}}</div>
                <h1 class="my-6 text-center black--text">Акт звірки взаєморозрахунків</h1>
                <v-alert type="warning" border="bottom" colored-border elevation="2" icon="mdi-alert">
                    <ul class="black--text caption">
                        <!-- <v-icon color="">mdi-alert</v-icon> <b>УВАГА!</b> -->
                        <li>Компанія ніколи не приймає оплату за товар на особисті картки своїх співробітників.</li>
                        <li>Компанія ніколи не використовує ваш баланс для виписки товару іншим клієнтам компанії.</li>
                        <li>Якщо у вас виникли будь-які питання по балансу, будь ласка, повідомте про це нам (кнопка "Помітили неточності?").</li>
                        <li>Погоджуючись з викривленим балансом ви берете на себе повну відповідальність за його погашення. </li>
                    </ul>
                </v-alert>
                <!-- {{dogsData}} -->
                <div v-if="dogs[0]" class="pt-5">
                    <v-simple-table dense class="balance-sum">
                        <template v-slot:default>
                            <thead>
                                <tr class="blue-grey lighten-2">
                                    <th colspan="2" width="80%">
                                    </th>
                                    <th colspan="2" class="text-center" width="20%">
                                        UAH
                                    </th>
                                </tr>
                                <tr class="blue-grey lighten-3">
                                    <th colspan="2" width="80%">
                                    </th>
                                    <th class="text-center" width="10%">
                                        Дебет
                                    </th>
                                    <th class="text-center" width="10%">
                                        Кредит
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="blue-grey lighten-5">
                                    <td class="px-1 px-sm-4">Загальне сальдо на:</td>
                                    <td class="px-1 px-sm-4">{{formatDate(dogsData.begin_date)}}</td>
                                    <td colspan="2" class="text-center px-1 px-sm-4">{{roundTo(totals.begin_volume)}}</td>
                                </tr>
                                <tr class="blue-grey lighten-5">
                                    <td colspan="2" class="px-1 px-sm-4">Оборот за період:</td>
                                    <td class="text-center px-1 px-sm-4">{{roundTo(totals.ob_za_period_dt)}}</td>
                                    <td class="text-center px-1 px-sm-4">{{roundTo(totals.ob_za_period_kt)}}</td>
                                </tr>
                                <tr class="blue-grey lighten-5">
                                    <td class="px-1 px-sm-4">Загальне сальдо на:</td>
                                    <td class="px-1 px-sm-4">{{formatDate(dogsData.end_date)}}</td>
                                    <td colspan="2" class="text-center px-1 px-sm-4">{{roundTo(totals.end_volume)}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
                <div class="d-flex justify-space-between flex-column flex-sm-row">
                    <v-btn dark color="warning" class="mt-5" v-if="permitions" @click="dialogFeedback = !dialogFeedback">Помітили неточності?</v-btn>
                    <v-btn dark color="primary" class="mt-5" v-if="canReconciliation && !approved && !validationDateError && !token && !loading" @click="checkPhone()">Підтвердити звірку по всім договорам</v-btn>
                    <!-- Блок подтверждения -->
                    <div v-if="approved" class="mt-5 accent2--text d-flex align-center justify-space-around justify-sm-end">
                        Підтвердження звірки прийнято {{now()}}
                    </div>
                    <div v-if="validationDateError && canReconciliation" class="mt-5 primary--text d-flex align-center justify-space-around justify-sm-end">
                        Підтвердження звірки вже було прийнято раніше
                    </div>
                </div>
                <div class="pa-10 text-center" v-if="loading">
                    <v-progress-circular :size="70" class="" :width="7" color="pink" indeterminate></v-progress-circular>
                </div>
                <v-alert class="mt-10 mx-auto" v-if="error" type="error" :width="$vuetify.breakpoint.smAndUp ? `800` : `99%`">
                    {{errorText}}
                </v-alert>
                <v-card v-if="canReconciliation && !approved && !validationDateError && token && !phoneChecked" :width="$vuetify.breakpoint.smAndUp ? `600` : `99%`" class="mx-auto mt-6 pa-4" elevation="6">
                    <p class="error--text pl-8">На Ваш телефон {{phone}} було надіслано SMS з кодом підтвердження.</p>
                    <v-text-field persistent-hint hint="Введiть SMS-код підтвердження" prepend-icon="mdi-cellphone-text" name="code" label="Код з SMS" v-model="code"></v-text-field>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="my-5" color="primary" dark @click="approve()">Підтвердити</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-card-text>
        </v-card>
        <v-card flat tile class="mx-auto" :width="mainWidth" v-if="permitions">
            <v-btn text @click="createXlsx()" :disabled="loaded" class="px-2 px-sm-4 mt-8 mb-4">
                <span class="mr-2">
                    <v-icon color="link">{{exclelIcon}}</v-icon>
                </span>
                <span>Завантажити Excel</span>
                <v-progress-circular v-if="xlsLoading" :width="7" color="primary" indeterminate></v-progress-circular>
            </v-btn>
        </v-card>
        <v-card flat tile v-for="(dog, idx) in dogs" :width="mainWidth" class="mx-auto mb-6" :key="idx">
            <v-card-title class="py-0 px-2 px-sm-4 link2--text">{{dog.name}}</v-card-title>
            <v-card-text class="px-2 px-sm-4">
                <v-data-table class="balance" v-model="selected[dog.name]" show-select @update:expanded="exp" dense :items-per-page="10000" :headers="headers" :items="dog.doc" :expanded.sync="expanded[idx]" item-key="num" show-expand hide-default-footer mobile-breakpoint="0">
                    <!-- Чекбокс -->
                    <template v-slot:header.data-table-select>
                        <v-menu open-on-hover top offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" small>mdi-help-circle</v-icon>
                            </template>
                            <v-card>
                                <v-card-text>
                                    Оберіть рядки з розбіжностями.<br>
                                    Для відправки по ним інформації менеджерам натисніть<br>
                                    кнопку <span class="warning--text">"Помітили неточності?"</span>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </template>
                    <template v-slot:top>
                        <v-simple-table dense class="balance">
                            <template v-slot:default>
                                <thead>
                                    <tr class="blue-grey lighten-2">
                                        <th colspan="2" width="80%">
                                        </th>
                                        <th colspan="2" class="text-center" width="20%">
                                            UAH
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="blue-grey lighten-5">
                                        <td class="px-1 px-sm-4" width="20%">Сальдо на:</td>
                                        <td class="px-1 px-sm-4 text-center" width="60%">{{formatDate(dogsData.begin_date)}}</td>
                                        <td colspan="2" class="text-center px-1 px-sm-4" width="20%">{{roundTo(dog.begin_volume)}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                    <template v-slot:footer>
                        <v-simple-table dense class="balance">
                            <template v-slot:default>
                                <thead>
                                    <tr class="blue-grey lighten-5">
                                        <th colspan="2" width="80%" class="px-1 px-sm-4">
                                            Оборот за період:
                                        </th>
                                        <th class="text-center px-1 px-sm-4" width="10%">
                                            {{roundTo(dog.ob_za_period_dt)}}
                                        </th>
                                        <th class="text-center px-1 px-sm-4" width="10%">
                                            {{roundTo(dog.ob_za_period_kt)}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="blue-grey lighten-4">
                                        <td class="px-1 px-sm-4" width="20%">Сальдо на:</td>
                                        <td class="px-1 px-sm-4 text-center" width="60%">{{formatDate(dogsData.end_date)}}</td>
                                        <td colspan="2" class="text-center px-1 px-sm-4" width="20%">{{roundTo(dog.end_volume)}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                    <template v-slot:item.date="{ item }">
                        {{formatDate(item.date)}}
                    </template>
                    <template v-slot:item.debet="{ item }">
                        {{roundTo(item.debet)}}
                    </template>
                    <template v-slot:item.credet="{ item }">
                        {{roundTo(item.credet)}}
                    </template>

                    <template v-slot:item.ho_name="{ item }">
                        {{hoObj[item.ho_num] ? hoObj[item.ho_num] : item.ho_name}}
                    </template>

                    <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                        <v-btn small class="ma-0 ml-n2" v-if="soldLst[item.num] || bonusesObj[item.num] || actionsObj[item.num] || item.ho_num == 4055 || item.ho_num == 2737" @click="expand(!isExpanded)" icon>
                            <v-icon>{{isExpanded ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <!-- БиШ часть -->
                        <!-- Бонусы -->
                        <template v-if="item.ho_num == 4055">
                            <td :colspan="headers.length" class="px-1 blue-grey lighten-5">
                                <div v-if="!bishObj[item.num]">Зачекайте...</div>
                                <v-data-table class="my-1 products" dense :items-per-page="1000" :headers="bishHeaders" :items="bishObj[item.num]" hide-default-footer mobile-breakpoint="0">
                                    <template v-slot:item.date="{ item }">
                                        {{formatDate(item.date)}}
                                    </template>
                                    <template v-slot:item.base="{ item }">
                                        {{item.sum_uah > 0 ? item.base_uah : 0}}
                                    </template>
                                    <template v-slot:no-data>
                                        Зачекайте...
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                        <!-- Штрафы -->
                        <template v-if="item.ho_num == 2737">
                            <td :colspan="headers.length" class="px-1 blue-grey lighten-5">
                                <div v-if="!bishObj[item.num]">Зачекайте...</div>
                                <v-data-table class="my-1 products" dense :items-per-page="1000" :headers="bishFineHeaders" :items="bishObj[item.num]" hide-default-footer mobile-breakpoint="0">
                                    <template v-slot:item.date="{ item }">
                                        {{formatDate(item.date)}}
                                    </template>
                                    <template v-slot:item.base="{ item }">
                                        {{item.sum_uah > 0 ? item.base_uah : 0}}
                                    </template>
                                    <template v-slot:no-data>
                                        Зачекайте...
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                        
                        <template v-if="item.ho_num != 2737 && item.ho_num != 4055 ">
                            <!-- Акція призова -->
                            <template v-if="actionsObj[item.num]">
                                <td :colspan="headers.length" class="blue-grey lighten-4 pa-3 caption pl-12">
                                    <div class="pl-10">
                                        Нарахування по акції: "{{ actionsObj[item.num].name }}" (№ <a target="_blank" :href="`https://b2b.yugcontract.ua/action/${actionsObj[item.num].action_id}`"> <span>{{actionsObj[item.num].action_id}}</span></a>)
                                    </div>
                                </td>
                            </template>
                            <!-- БП -->
                            <template v-if="bonusesObj[item.num]">
                                <td :colspan="headers.length" class="blue-grey lighten-4 pa-3 caption pl-12">
                                    <div class="pl-10">Нарахування бонусу: № {{bonusesObj[item.num].id}}
                                        ({{entities.decode(bonusesObj[item.num].bp_type)}}<span v-if="bonusesObj[item.num].bp_type == 'трансляція програми'">_{{entities.decode(bonusesObj[item.num].tr_name)}}</span> )
                                        / {{bonusesObj[item.num].calc_date}} / Прим.: {{bonusesObj[item.num].comment_doc}}
                                    </div>
                                </td>
                            </template>
                            <!-- Таблица товаров -->
                            <template v-if="!bonusesObj[item.num] && !actionsObj[item.num]">
                                <td :colspan="headers.length" class="px-1 blue-grey lighten-5">
                                    <v-data-table class="my-1 products" dense :items-per-page="1000" :headers="ordersHeaders" :items="soldLst[item.num]" hide-default-footer mobile-breakpoint="0">
                                        <template v-slot:item.sn="{ item }">
                                            <div v-if="item.sn && item.sn.split(',').length == 1">{{item.sn}}</div>
                                            <div @click="openSn(item.sn)" style="cursor: pointer" class="link--text text-decoration-underline" v-if="item.sn && item.sn.split(',').length > 1">Показати SN ({{item.sn && item.sn.split(',').length}})</div>
                                        </template>
                                        <template v-slot:item.model="{ item }">
                                            <v-menu max-width="200" offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" style="cursor: pointer">{{item.model}}</span>
                                                </template>
                                                <v-img height="200" width="200" :src="item.img"></v-img>
                                            </v-menu>
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                        </template>
                    </template>
                    <template v-slot:no-data>
                        Оборотів немає
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-card>

    <v-dialog v-model="dialog" max-width="450">
        <v-card>
            <v-card-title class="headline">
                Перелік серійних номерів товару
            </v-card-title>

            <v-card-text class="pl-12">
                <div v-for="n in sn.split(',')">{{n}}</div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text v-if="!loading" @click="dialog = false">
                    Закрити
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFeedback" max-width="1000">
        <v-card>
            <v-card-title class="headline px-1 px-sm-4">
                Повідомити про неточності
            </v-card-title>

            <v-card-text>
                <div v-if="feedbackSent" class="py-10 title error--text">
                    Дякуємо!<br>
                    Ваше повідомлення було надіслано менеджеру та керівнику каналу збуту.
                </div>
                <div v-if="!feedbackSent">
                    Акт звірки по контрагенту за період з {{formatDate(dogsData.begin_date)}} по {{formatDate(dogsData.end_date)}}

                    <v-card flat tile v-for="(dog, idx) in dogs" :key="idx" v-if="selected[dog.name]" :width="mainWidth" class="mx-auto my-6">
                        <v-card-title class="primary--text">{{dog.name}}</v-card-title>
                        <v-card-text>
                            <v-data-table dense :items-per-page="100" sort-by="date" :headers="headers" :items="selected[dog.name]" item-key="num" class="balance" hide-default-footer mobile-breakpoint="0">
                                <template v-slot:item.date="{ item }">
                                    {{formatDate(item.date)}}
                                </template>
                                <template v-slot:item.debet="{ item }">
                                    {{roundTo(item.debet)}}
                                </template>
                                <template v-slot:item.credet="{ item }">
                                    {{roundTo(item.credet)}}
                                </template>

                                <template v-slot:item.ho_name="{ item }">
                                    {{hoObj[item.ho_num] ? hoObj[item.ho_num] : item.ho_name}}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>

                    <div class="pa-10">
                        <v-textarea outlined v-model="feedbackText" label="Ваш коментар *"></v-textarea>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-btn text v-if="!loading" @click="dialogFeedback = false">
                    Закрити
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="pink" text :disabled="!(!loading && !feedbackSent && feedbackText)" @click="sendFeedback()">
                    Надіслати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- <pre>
    {{dogs}}
    {{dogsData}}
    </pre> -->
</div>
</template>

<script>
import {
    mdiMicrosoftExcel
} from '@mdi/js'

import {
    connect
} from "../Socket"

import axios from 'axios'
const Entities = require('html-entities').XmlEntities;
const HTTP = axios.create({
    baseURL: (process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/' : 'http://dev.yugcontract.ua:3033/api/'),
})
export default {
    components: {},
    data: vm => ({
        exclelIcon: mdiMicrosoftExcel,
        selected: {},
        soapResponse: '',
        dialog: false,
        sn: '',
        se: {},
        socket: {},
        loaded: false,
        approved: false,
        loading: false,
        xlsLoading: false,
        error: false,
        errorText: '',
        dialogFeedback: false,
        feedbackSent: false,
        mime: {
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            xml: "application/xml"
        },
        erroreCode: {
            424: 'Відсутній або невірний Контактний телефон для надсилання SMS',
            401: 'Немає доступу до інформації',
            403: 'Код невірний',
            500: 'сервер 500'
        },
        mainWidth: '1200',
        expanded: {},
        canReconciliation: false,
        headers: [
            // {
            //     text: '',
            //     value: 'data-table-expand',
            //     width: '5%'
            // },
            {
                text: 'Дата',
                align: 'left',
                sortable: false,
                value: 'date',
                width: '5%'
            },
            {
                text: 'Маш. №',
                align: 'center',
                sortable: false,
                value: 'num',
                width: '10%'
            },
            {
                text: 'Хоз. операція',
                align: 'left',
                sortable: false,
                value: 'ho_name',
                width: '65%'
            },
            {
                text: 'Дебет',
                align: 'right',
                sortable: false,
                value: 'debet',
                width: '10%'
            },

            {
                text: 'Кредит',
                align: 'right',
                sortable: false,
                value: 'credet',
                width: '10%'
            },
        ],
        ordersHeaders: [{
                text: 'Код',
                align: 'center',
                sortable: false,
                value: 'typhoon_id',
                width: '5%'
            },
            {
                text: 'Артикул',
                align: 'center',
                sortable: false,
                value: 'artikul',
                width: '15%'
            },
            {
                text: 'Бренд',
                align: 'left',
                sortable: false,
                value: 'brand_name',
                width: '15%'
            },
            {
                text: 'Товар',
                align: 'left',
                sortable: false,
                value: 'model',
                width: '35%'
            },
            {
                text: 'SN',
                align: 'center',
                sortable: false,
                value: 'sn',
                width: '15%'
            },
            {
                text: 'К-ть',
                align: 'center',
                sortable: false,
                value: 'quantity',
                width: '5%'
            },
            {
                text: 'Ціна, ₴',
                align: 'center',
                sortable: false,
                value: 'price_doc',
                width: '5%'
            },
            {
                text: 'Сума, ₴',
                align: 'center',
                sortable: false,
                value: 'sum_doc',
                width: '5%'
            },
        ],
        bishHeaders: [{
                text: 'Дата',
                align: 'center',
                sortable: false,
                value: 'date',
                width: '10%'
            },
            {
                text: 'Сплачено заздалегідь, ₴',
                align: 'center',
                sortable: false,
                value: 'base_uah',
                width: '30%'
            },
            {
                text: 'База для нарахування, ₴',
                align: 'center',
                sortable: false,
                value: 'base',
                width: '30%'
            },
            {
                text: 'Нарахований бонус, ₴',
                align: 'center',
                sortable: false,
                value: 'sum_uah',
                width: '30%'
            },
        ],
        bishFineHeaders: [{
                text: 'Дата',
                align: 'center',
                sortable: false,
                value: 'date',
                width: '10%'
            },
            {
                text: 'Протермінований борг, ₴',
                align: 'center',
                sortable: false,
                value: 'base_uah',
                width: '30%'
            },
            {
                text: 'База для нарахування, ₴',
                align: 'center',
                sortable: false,
                value: 'base',
                width: '30%'
            },
            {
                text: 'Нарахований штраф, ₴',
                align: 'center',
                sortable: false,
                value: 'sum_uah',
                width: '30%'
            },
        ],
        soldLst: {},
        hoObj: {},
        bonusesObj: {},
        actionsObj: {},
        entities: new Entities(),
        bishObj: {},
        code: null,
        phone: '',
        token: '',
        phoneChecked: false,
        validationDateError: false,
        feedbackText: '',
        fileBlobURL: '',
        permitions: false
    }),
    methods: {
        now() {
            return new Date().toLocaleString('ru-RU')
        },
        roundTo(v, n = 2) {
            return parseFloat(v).toLocaleString('ua-UA', {
                style: 'currency',
                currency: 'UAH'
            })
        },
        exp(value) {
            if (value[0] && (value[0].ho_num == 4055 || value[0].ho_num == 2737)) {
                this.getTaxDetails(value[0].num)
                    .then(data => this.$set(this.bishObj, value[0].num, data))
                    .catch(error => console.log(error))
            }
        },
        createReport() {
            const params = (new URL(document.location)).searchParams
            const token = params.get('t')
            HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`
            this.loading = true
            HTTP.post('/balance/create-report', {})
                .then(response => {
                    this.loading = false
                    if (response.data && response.data.content) {
                        const data = response.data.content
                        this.soapResponse = this.handling(data.soapResponse)
                        this.handlingSold(data.soldLst)
                        this.hoObj = data.hoObj
                        this.bonusesObj = data.bonusesObj
                        this.actionsObj = data.actionsObj
                        this.canReconciliation = data.reconciliation
                        this.permitions = data.permitions
                    }
                })
                .catch(e => {
                    this.loading = false
                    console.error(e)
                    this.error = true
                    this.errorText = this.erroreCode[e.response.status] || e
                })
        },
        getTaxDetails(docId) {
            return new Promise(async (resolve, reject) => {
                const params = (new URL(document.location)).searchParams
                const token = params.get('t')
                HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`
                HTTP.post('/balance/get-tax-details', {
                        docId
                    })
                    .then(response => {
                        this.loading = false
                        if (response.data && response.data.content) {
                            const data = JSON.parse(response.data.content)
                            if (data && data['soap:Envelope'] && data['soap:Envelope']['soap:Body']['GetTaxDetailsResponse']['GetTaxDetailsResult']['FineBonus'])
                                resolve(data['soap:Envelope']['soap:Body']['GetTaxDetailsResponse']['GetTaxDetailsResult']['FineBonus'])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        this.error = true
                        this.errorText = this.erroreCode[e.response.status] || e
                    })
            })
        },
        formatDate(d) {
            if (!d) return ''
            const [date, time] = d.split('T')
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        handlingSold(data = []) {
            for (let doc of data) {
                if (!this.soldLst[doc.doc_name]) {
                    this.soldLst[doc.doc_name] = []
                }
                this.soldLst[doc.doc_name].push(doc)
            }
        },
        handling(data) {
            return this.orderDocsByDate(data)
        },
        orderDocsByDate(data) {
            if (data && data['soap:Envelope'] &&
                data['soap:Envelope']['soap:Body'] &&
                data['soap:Envelope']['soap:Body']['Verification_dogResponse'] &&
                data['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult'] &&
                data['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult']['Dogovors'] &&
                data['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult']['Dogovors']['Dogovor']
            ) {
                let dogs = data['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult']['Dogovors']['Dogovor']
                if (dogs.id) dogs = [dogs]

                for (let dog of dogs) {
                    //create number from string
                    dog.begin_volume = Number.parseFloat(dog.begin_volume)
                    dog.end_volume = Number.parseFloat(dog.end_volume)
                    dog.ob_za_period_dt = Number.parseFloat(dog.ob_za_period_dt)
                    dog.ob_za_period_kt = Number.parseFloat(dog.ob_za_period_kt)
                    if (dog.doc && typeof dog.doc[0] === 'object') {
                        dog.doc.sort((a, b) => {
                            return new Date(a.date) - new Date(b.date)
                        })
                    } else {
                        if (dog.doc) {
                            dog.doc = [dog.doc]
                        } else {
                            dog.doc = []
                        }
                    }
                }
                return data
            }
        },
        openSn(sn) {
            this.dialog = true
            this.sn = sn
        },
        checkPhone() {
            this.error = false
            this.errorText = ''
            const params = (new URL(document.location)).searchParams
            const token = params.get('t')
            HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`
            this.loading = true
            HTTP.post('/balance/check-phone', {})
                .then(response => {
                    this.loading = false
                    if (response.data && response.data.content) {
                        this.phone = response.data.content.hphone
                        this.token = response.data.content.token
                    }
                })
                .catch(e => {
                    this.loading = false
                    console.error(e)
                    this.error = true
                    this.errorText = this.erroreCode[e.response.status] || e
                })

        },
        approve() {
            this.error = false
            this.errorText = ''
            const params = (new URL(document.location)).searchParams
            const token = params.get('t')
            HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`
            this.loading = true

            HTTP.post('/balance/approve', {
                    token: this.token,
                    code: this.code
                })
                .then(response => {
                    this.loading = false
                    if (response.data && response.data.content) {
                        this.phoneChecked = response.data.content.phoneChecked
                        this.approved = response.data.content.soap && response.data.content.soap.approved
                        if (!this.approved) {
                            this.error = true
                            this.errorText = 'Помилка при отриманні даних. Спробуйте пізніше'
                        }
                    }
                })
                .catch(e => {
                    this.loading = false
                    console.error(e)
                    this.error = true
                    this.errorText = this.erroreCode[e.response.status] || e
                })

        },
        checkValidationDate() {
            this.error = false
            this.errorText = ''
            const params = (new URL(document.location)).searchParams
            const token = params.get('t')
            HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`
            this.loading = true
            return new Promise((resolve, reject) => {
                HTTP.post('/balance/check-validation-date', {})
                    .then(response => {
                        this.loading = false
                        if (response.data && response.data.content) {
                            this.validationDateError = response.data.content.invalid
                            resolve()
                        } else {
                            reject('Невiдома помилка')
                        }
                    })
                    .catch(e => {
                        this.loading = false
                        console.error(e)
                        this.error = true
                        this.errorText = this.erroreCode[e.response.status] || e
                        reject(e)
                    })
            })
        },
        sendFeedback() {
            this.error = false
            this.errorText = ''
            const params = (new URL(document.location)).searchParams
            const token = params.get('t')
            HTTP.defaults.headers.common['Authorization'] = `Bearer ${token}`
            this.loading = true
            const feedbackText = this.feedbackText
            const data = this.selected
            return new Promise((resolve, reject) => {
                HTTP.post('/balance/send-feedback', {
                        feedbackText,
                        data
                    })
                    .then(response => {
                        this.loading = false
                        if (response.data && response.data.content) {
                            this.feedbackSent = true
                            resolve()
                        } else {
                            reject('Невiдома помилка')
                        }
                    })
                    .catch(e => {
                        this.loading = false
                        console.error(e)
                        this.error = true
                        this.errorText = this.erroreCode[e.response.status] || e
                        reject(e)
                    })
            })
        },
        connectSocket() {
            const {
                se,
                socket
            } = connect()
            this.loaded = false
            this.xlsLoading = false
            this.se = se
            this.socket = socket

            this.se.$on("message", (msg) => {
                const date = new Date().toISOString()
                const filename = `${date}_balance.xlsx`
                this.fileName = filename
                this.saveData(msg, filename);
                this.loaded = false
                this.xlsLoading = false
            })
            this.se.$on("closed", () => {
                setTimeout(() => {
                    this.closed = true
                    console.log("Websocket closed")
                }, 5000);
            })
            this.se.$on("error", (error) => {
                console.log("Не вдалося встановити з'єднання з сервером")
                //this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером"])
            })
            this.se.$on("opened", () => {
                this.closed = false
                this.xlsLoading = false
            })
        },
        createXlsx() {
            window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
            this.fileBlobURL = ''
            this.loaded = true
            this.xlsLoading = true
            const params = (new URL(document.location)).searchParams
            const token = params.get('t')
            this.se.send(JSON.stringify({
                fileName: 'balanceFininfo.xlsx',
                authToken: token,
                type: 'balance'
            }))
        },
        saveData(data, fileName, format) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const blob = new Blob([data], {
                type: this.mime[this.format]
            })
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },
    },
    watch: {
        dialogFeedback(v) {
            if (v && this.feedbackSent) {
                this.error = false
                this.errorText = ''
                this.feedbackSent = false
                this.feedbackText = ''
            }
        }
    },
    computed: {
        dogsData() {
            if (this.soapResponse && this.soapResponse['soap:Envelope'] &&
                this.soapResponse['soap:Envelope']['soap:Body'] &&
                this.soapResponse['soap:Envelope']['soap:Body']['Verification_dogResponse'] &&
                this.soapResponse['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult'] &&
                this.soapResponse['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult']['Dogovors']
            ) {
                return this.soapResponse['soap:Envelope']['soap:Body']['Verification_dogResponse']['Verification_dogResult']['Dogovors']
            } else {
                return []
            }
        },
        dogs() {
            if (this.dogsData && this.dogsData['Dogovor']) {
                let dogs = this.dogsData['Dogovor']
                if (dogs.id) dogs = [dogs]
                return dogs
            } else {
                return []
            }
        },
        totals() {
            const totals = {
                begin_volume: 0,
                end_volume: 0,
                ob_za_period_dt: 0,
                ob_za_period_kt: 0
            }
            for (let el of this.dogs) {
                totals.begin_volume = totals.begin_volume + Number(el.begin_volume)
                totals.end_volume = totals.end_volume + Number(el.end_volume)
                totals.ob_za_period_dt = totals.ob_za_period_dt + Number(el.ob_za_period_dt)
                totals.ob_za_period_kt = totals.ob_za_period_kt + Number(el.ob_za_period_kt)
            }
            totals.begin_volume = Math.round(totals.begin_volume * 100) / 100
            totals.end_volume = Math.round(totals.end_volume * 100) / 100
            totals.ob_za_period_kt = Math.round(totals.ob_za_period_kt * 100) / 100
            totals.ob_za_period_dt = Math.round(totals.ob_za_period_dt * 100) / 100

            return totals
        },
    },
    created() {
        this.checkValidationDate()
            .then(() => {
                this.createReport()
                this.connectSocket()
            })
            .catch(error => console.log(error))

    }
}
</script>

<style>
.v-data-table.products tbody td {
    background: #E3F2FD !important;
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300 !important;
}

.v-data-table.balance>.v-data-table__wrapper .v-data-table__mobile-row {
    padding: 0;
    min-height: inherit;
}

.v-data-table.balance>.v-data-table__wrapper td:nth-child(1),
.v-data-table.balance>.v-data-table__wrapper td:nth-child(2) {
    padding-right: 0;
}

.v-data-table.balance>.v-data-table__wrapper td:nth-child(2) {
    padding-left: 12px;
}

@media (max-width: 512px) {
    .v-data-table.balance>.v-data-table__wrapper td:nth-child(1) {
        padding-left: 5px;
    }

    .v-data-table.balance-sum>.v-data-table__wrapper th,
    .v-data-table.balance-sum>.v-data-table__wrapper td,
    .v-data-table.balance>.v-data-table__wrapper th,
    .v-data-table.balance>.v-data-table__wrapper td,
    .v-data-table.balance>.v-data-table__wrapper td:nth-child(2) {
        padding: 0 5px 0 0;
        font-size: 12px;
    }

    .v-data-table.balance>.v-data-table__wrapper th:nth-child(1),
    .v-data-table.balance>.v-data-table__wrapper th:nth-child(2) {
        width: 5% !important;
    }

    .v-data-table.balance>.v-data-table__wrapper th:nth-child(5),
    .v-data-table.balance>.v-data-table__wrapper td:nth-child(5) {
        display: none;
    }

    .v-data-table.products>.v-data-table__wrapper th:nth-child(1),
    .v-data-table.products>.v-data-table__wrapper td:nth-child(1),
    .v-data-table.products>.v-data-table__wrapper th:nth-child(2),
    .v-data-table.products>.v-data-table__wrapper td:nth-child(2) {
        display: none;
    }
}
</style>
