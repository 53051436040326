import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#e50040', //logo red
            second: '#333333',  //font
            third: '#ffb048',   //orange
            accent: '#fcf4db',  //yellow light 
            accent2: '#8BC34A', //light-green mdi 
            link: '#10457d',    //blue dark
            link2: '#62b5e4',   //blue light
            error: '#ec1c24',   //red
            backgr: '#b1c3d2',
          },
        },
      }
    });
